import type { ValueOf } from '@daangn/car-utils/type';

export const AuctionTradeProsCarExtra = {
  INCLUDES_BLACK_BOX: 'INCLUDES_BLACK_BOX',
  NON_SMOKE: 'NON_SMOKE',
  ADDITIONAL_PURCHASE_OF_EXTENDED_WARRANTY: 'ADDITIONAL_PURCHASE_OF_EXTENDED_WARRANTY',
  PERFORMANCE_TUNING: 'PERFORMANCE_TUNING',
  WINTER_TIRE: 'WINTER_TIRE',
  ROOF_BOX: 'ROOF_BOX',
  EXTRA: 'EXTRA',
  REPLACES_CAR_SUPPLIES_IN_SIX_MONTHS: 'REPLACES_CAR_SUPPLIES_IN_SIX_MONTHS',
  REPAIRS_IMPORTANT_PARTS_IN_SIX_MONTHS: 'REPAIRS_IMPORTANT_PARTS_IN_SIX_MONTHS',
  REPAIRS_EXTRA_PARTS_IN_SIX_MONTHS: 'REPAIRS_EXTRA_PARTS_IN_SIX_MONTHS',
} as const;

export type AuctionTradeProsCarExtra = ValueOf<typeof AuctionTradeProsCarExtra>;

export const AuctionTradeProsCarExtraText: Record<AuctionTradeProsCarExtra, string> = {
  ADDITIONAL_PURCHASE_OF_EXTENDED_WARRANTY: '보증 연장 상품 추가 구매',
  NON_SMOKE: '금연 차량',
  PERFORMANCE_TUNING: '퍼포먼스 튜닝 (엔진, 흡배기)',
  WINTER_TIRE: '윈터 타이어',
  ROOF_BOX: '루프박스',
  EXTRA: '기타',
  REPLACES_CAR_SUPPLIES_IN_SIX_MONTHS: '소모품 교환',
  REPAIRS_IMPORTANT_PARTS_IN_SIX_MONTHS: '엔진 · 미션 등 주요부품 수리',
  REPAIRS_EXTRA_PARTS_IN_SIX_MONTHS: '기타',
  INCLUDES_BLACK_BOX: '블랙박스 포함',
};
