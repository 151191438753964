import type { MechanicReport } from './MechanicReport';
import type { ReportItemState } from './ReportItemState';

export type MechanicReportFormValue = MechanicReport;
export const defaultChecklist: ReportItemState = {
  selections: [],
  comment: '',
  imgs: [],
};
export const noneChecklist: ReportItemState = {
  selections: ['None'],
  comment: '',
  imgs: [],
};
export const defaultMechanicReportFormValue: MechanicReport = {
  baseInfo: {
    tuningHistory: defaultChecklist,
    powerSteeringType: undefined as any,
    gearType: undefined as any,
    powertrainLayout: undefined as any,
    driveDistance: undefined as any,
    fuelType: undefined as any,
  },
  tire: {
    tireCondition: {
      front: defaultChecklist,
      rear: defaultChecklist,
    },
    brakePadWear: {
      front: undefined as any,
      rear: undefined as any,
    },
    productionDate: {} as any,
    tireTreadDepth: {} as any,
  },
  accidentHistory: {
    externalPanel: {
      leftFender: 'NoIssues',
      rightFender: 'NoIssues',
      leftFrontDoor: 'NoIssues',
      rightFrontDoor: 'NoIssues',
      leftRearDoor: 'NoIssues',
      rightRearDoor: 'NoIssues',
      leftQuarterPanel: 'NoIssues',
      rightQuarterPanel: 'NoIssues',
      leftSideSealPanel: 'NoIssues',
      rightSideSealPanel: 'NoIssues',
      hood: 'NoIssues',
      trunkLid: 'NoIssues',
      roofPanel: 'NoIssues',
      radiatorSupport: 'NoIssues',
    },
    mainFrame: {
      frontPanel: 'NoIssues',
      rearPanel: 'NoIssues',
      leftInsidePanel: 'NoIssues',
      rightInsidePanel: 'NoIssues',
      leftFrontWheelHouse: 'NoIssues',
      rightFrontWheelHouse: 'NoIssues',
      leftRearWheelHouse: 'NoIssues',
      rightRearWheelHouse: 'NoIssues',
      leftFrontSideMember: 'NoIssues',
      rightFrontSideMember: 'NoIssues',
      leftRearSideMember: 'NoIssues',
      rightRearSideMember: 'NoIssues',
      crossMember: 'NoIssues',
      dashPanel: 'NoIssues',
      floorPanel: 'NoIssues',
      packageTray: 'NoIssues',
      leftPillarPanelA: 'NoIssues',
      leftPillarPanelB: 'NoIssues',
      leftPillarPanelC: 'NoIssues',
      leftPillarPanelD: 'NoIssues',
      rightPillarPanelA: 'NoIssues',
      rightPillarPanelB: 'NoIssues',
      rightPillarPanelC: 'NoIssues',
      rightPillarPanelD: 'NoIssues',
      trunkFloor: 'NoIssues',
    },
  },
  engine: {
    operation: {
      ignition: defaultChecklist,
      idle: defaultChecklist,
      acceleration: defaultChecklist,
    },
    engineOil: {
      flow: defaultChecklist,
      condition: defaultChecklist,
    },
    oilLeak: {
      rockerArmCover: defaultChecklist,
      cylinderHead: defaultChecklist,
      engineBlock: defaultChecklist,
      oilPan: defaultChecklist,
      frontCover: defaultChecklist,
    },
    coolant: {
      level: defaultChecklist,
      condition: defaultChecklist,
    },
    coolantLeak: {
      cylinderHead: defaultChecklist,
      radiator: defaultChecklist,
      waterPump: defaultChecklist,
    },
    highPressurePump: {
      highPressurePump: defaultChecklist,
    },
  },
  autoTransmission: {
    operation: {
      PRND: defaultChecklist,
      DNRP: defaultChecklist,
    },
    missionOil: {
      level: defaultChecklist,
      condition: defaultChecklist,
    },
    oilLeak: {
      oilPan: defaultChecklist,
      missionCase: defaultChecklist,
      pump: defaultChecklist,
    },
  },
  manualTransmission: {
    gearShift: {
      gearShift: defaultChecklist,
    },
    oilLeak: {
      oilLeak: defaultChecklist,
    },
    operation: {
      operation: defaultChecklist,
    },
  },
  powertrain: {
    driveShaft: {
      rubberBootDamage: defaultChecklist,
      greaseLeak: defaultChecklist,
    },
    differential: {
      caseLeak: defaultChecklist,
      oilSealLeak: defaultChecklist,
    },
    transferCase: {
      caseLeak: defaultChecklist,
      oilSealLeak: defaultChecklist,
    },
    propellerShaft: {
      shaftLooseness: defaultChecklist,
      discDamage: defaultChecklist,
      bearingDamage: defaultChecklist,
    },
    clutchAssembly: {
      clutchAssembly: defaultChecklist,
    },
  },
  steering: {
    operation: {
      steeringLooseness: defaultChecklist,
    },
    powerOil: {
      level: defaultChecklist,
    },
    oilLeak: {
      pump: defaultChecklist,
      gearBox: defaultChecklist,
      highPressureHose: defaultChecklist,
    },
    tieRodAndBallJoint: {
      tieRodAndBallJoint: defaultChecklist,
    },
  },
  brakingSystem: {
    operation: {
      vacuumBrakeBooster: defaultChecklist,
    },
    brakeFluid: {
      brakeFluid: defaultChecklist,
    },
    oilLeak: {
      masterCylinder: defaultChecklist,
      caliper: defaultChecklist,
    },
    suspension: {
      suspension: defaultChecklist,
    },
  },
  electricalSystem: {
    generator: {
      outputVoltage: defaultChecklist,
      noise: defaultChecklist,
    },
    starterMotor: {
      operation: defaultChecklist,
    },
    radiatorFanMotor: {
      radiatorFanMotor: defaultChecklist,
    },
  },
  highVoltageElectricalSystem: {
    batteryIsolation: {
      isolation: defaultChecklist,
    },
    chargingPortIsolation: {
      isolation: defaultChecklist,
    },
    wire: {
      wire: defaultChecklist,
    },
  },
  fuelSystem: {
    fuelLeak: {
      gasolineLeak: defaultChecklist,
      dieselLeak: defaultChecklist,
      lpgLeak: defaultChecklist,
    },
  },
  paint: {
    paint: {
      sameColor: defaultChecklist,
      differentColor: defaultChecklist,
      wrapping: defaultChecklist,
      ppf: defaultChecklist,
    },
  },
  exterior: {
    front: {
      frontBumper: defaultChecklist,
      frontBumperMolding: defaultChecklist,
      grille: defaultChecklist,
      headlamp: defaultChecklist,
      turnSignal: defaultChecklist,
      frontWindshield: defaultChecklist,
      tinting: defaultChecklist,
      hood: defaultChecklist,
    },
    right: {
      fender: defaultChecklist,
      sideMirror: defaultChecklist,
      frontWheel: defaultChecklist,
      rearWheel: defaultChecklist,
      doorGarnish: defaultChecklist,
      doorMolding: defaultChecklist,
      fenderGarnish: defaultChecklist,
      quarterPanelGarnish: defaultChecklist,
      frontDoor: defaultChecklist,
      rearDoor: defaultChecklist,
      sideSealPanel: defaultChecklist,
      quarterPanel: defaultChecklist,
      aPillar: defaultChecklist,
      bPillar: defaultChecklist,
      cPillar: defaultChecklist,
      dPillar: defaultChecklist,
    },
    rear: {
      tailLamp: defaultChecklist,
      rearWindowTinting: defaultChecklist,
      rearBumper: defaultChecklist,
      rearBumperMolding: defaultChecklist,
      trunkLid: defaultChecklist,
    },
    left: {
      fender: defaultChecklist,
      sideMirror: defaultChecklist,
      frontWheel: defaultChecklist,
      rearWheel: defaultChecklist,
      doorGarnish: defaultChecklist,
      doorMolding: defaultChecklist,
      fenderGarnish: defaultChecklist,
      quarterPanelGarnish: defaultChecklist,
      frontDoor: defaultChecklist,
      rearDoor: defaultChecklist,
      sideSealPanel: defaultChecklist,
      quarterPanel: defaultChecklist,
      aPillar: defaultChecklist,
      bPillar: defaultChecklist,
      cPillar: defaultChecklist,
      dPillar: defaultChecklist,
    },
    muffler: {
      muffler: defaultChecklist,
    },
    roof: {
      roof: defaultChecklist,
    },
  },
  interiorCondition: {
    centerConsole: {
      centerConsole: defaultChecklist,
    },
    centerFascia: {
      centerFascia: defaultChecklist,
    },
    dashboard: {
      dashboard: defaultChecklist,
    },
    doorTrim: {
      doorTrim: defaultChecklist,
    },

    driverSeatMat: {
      driverSeatMat: defaultChecklist,
    },
    handle: {
      handle: defaultChecklist,
    },
    headliner: {
      pillarCover: defaultChecklist,
      roof: defaultChecklist,
    },
    seat: {
      row1: defaultChecklist,
      row2: defaultChecklist,
      row3: defaultChecklist,
    },
  },
  options: {
    climateControlSystem: {
      airConditioner: noneChecklist,
      autoAirConditioner: noneChecklist,
      heater: noneChecklist,
      blowerMotor: noneChecklist,
      blowerDirectionController: noneChecklist,
    },
    avSystem: {
      monitor: noneChecklist,
      radio: noneChecklist,
    },
    steeringHandle: {
      electricalSteeringHandle: noneChecklist,
    },
    aeb: {
      aeb: noneChecklist,
    },
    ldws: {
      ldws: noneChecklist,
    },
    parkingSensor: {
      front: noneChecklist,
      rear: noneChecklist,
    },
    dashboardCamera: {
      dashboardCamera: noneChecklist,
    },
    cruiseControl: {
      cruiseControl: noneChecklist,
    },
    hipass: {
      hipass: noneChecklist,
    },
    bluetooth: {
      bluetooth: noneChecklist,
    },
    bsd: {
      bsd: noneChecklist,
    },
    smartKey: {
      smartKey: noneChecklist,
    },
    tpms: {
      tpms: noneChecklist,
    },
    navigation: {
      navigation: noneChecklist,
      touchPanel: noneChecklist,
    },
    powerWindow: {
      powerWindow: noneChecklist,
    },
    wiper: {
      wiper: noneChecklist,
    },
    autoWiper: {
      autoWiper: noneChecklist,
    },
    autoFoldSideMirror: {
      autoFoldSideMirror: noneChecklist,
    },
    sunroof: {
      sunroof: noneChecklist,
    },
    panoramicSunroof: {
      panoramicSunroof: noneChecklist,
    },
    camera: {
      around: noneChecklist,
      rear: noneChecklist,
    },
    leatherSeat: {
      leatherSeat: noneChecklist,
    },
    powerSeat: {
      powerSeat: noneChecklist,
    },
    ventilatedSeat: {
      ventilatedSeat: noneChecklist,
    },
    heatedSeat: {
      heatedSeat: noneChecklist,
    },
    indoorLight: {
      indoorLight: noneChecklist,
    },
    hud: {
      hud: noneChecklist,
    },
    heatedSteeringWheel: {
      heatedSteeringWheel: noneChecklist,
    },
    headlamp: {
      headlamp: noneChecklist,
    },
    autoTrunk: {
      autoTrunk: noneChecklist,
    },
    epb: {
      epb: noneChecklist,
    },
  },
  photos: {
    carRegistration: [],
    exterior: [],
    interior: [],
    paperReport: [],
    wheel: [],
    option: [],
    underBody: [],
    maintenanceReceipts: [],
  },
  opinion: {
    comment: '',
    imgs: [],
  },
  diagnosis: {
    etc: [],
    engine: [],
    transmission: [],
    imgs: [],
    imgsAfterClear: [],
  },
  pros: '',
  notice: {
    imgs: [],
    comment: '',
  },
};
